import { useEffect, useReducer, useState } from "react";
import useQuery from "../hooks/useQuery";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import CircularProgress from "@mui/material/CircularProgress";
import { InputReducer } from "../reducers/InputReducer";
import { isValidCompleteName, isValidEmail } from "../helpers/general";

const Register = () => {

  const [companies,setCompanies] = useState([])
  const { getData } = useQuery();
  const getInitialData = async () => {
    const companies = await getData("contractingCompanies", localStorage.token);
    if(companies.data.status===200){
      setCompanies(companies.data.data)
    }
  };

  const [error, setError] = useState({ status: false, message: "" });
  // estado inicial del form

  const initialState = {
    complete_name: "",
    email: "",
    company: 0,
  };

  const [inputData, dispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { postData } = useQuery();
  const [sending, setSending] = useState(false);

  // ENVIO  DE REGISTRO

  const register = async () => {
    if (
      !isValidCompleteName(inputData.complete_name) ||
      !isValidEmail(inputData.email) ||
      inputData.company === 0
    ) {
      setError({
        status: true,
        message: "Please check all fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      complete_name: inputData.complete_name,
      email: inputData.email,
      contracting_company_id: inputData.company,
    };
    const response = await postData("projectLeaders", data, localStorage.token);
    if (response.data.status === 200) {
      console.log(response.data);
      setError({
        status: true,
        message: response.data.data.message,
        severity: "success",
      });
      setSending(false);
    } else {
      setSending(false);
      setError({
        status: true,
        message: "Error",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Add External Project Leader</h2>
        <p className="ml-20">
          In this section you may add the project leaders(managers, supervisors)
        </p>
        <section className="create create_project shape">
          <TextField
            className="input_form"
            id="complete_name"
            label="Ex: John Doe"
            name="complete_name"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.complete_name}
          />
          <TextField
            className="input_form"
            id="email"
            name="email"
            label="Ex: user@email.com"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.email}
          />
          <FormControl className="input_form" fullWidth>
            <InputLabel>Company</InputLabel>
            <Select
              labelId="company"
              value={inputData.company || ""}
              name="company"
              label="company"
              onChange={handleInputChange}
              id="company"
            >
              {companies?.map((company) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.short_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {sending ? (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          ) : (
            <MyButton onClick={register} text="CREATE" className="action" />
          )}

          {error.status && (
            <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
              {error.message}
            </Alert>
          )}
        </section>
      </section>
    </>
  );
};

export default Register;
