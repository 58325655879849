import {  createTheme } from '@mui/material/styles';

export  const theme = createTheme({
    palette: {
      primary: {
        light: '#00595C',
        main: '#004d50',
        dark: '#003436',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

/* export default function OverrideCssBaseline() {
  return (
    <ThemeProvider theme={theme}>
     <CssBaseline />
      <h1>Grey h1 element</h1>
    </ThemeProvider>
  );
} */
