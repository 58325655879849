import { DatePicker } from "@mui/x-date-pickers";

const DatePick = (props) => {
  const { setStart, setEnd, day } = props;

  return (
    <>
      <h2>Select the range</h2>
      <section className="selectors">
        <DatePicker label="Start" onChange={setStart} />
       {!day && <DatePicker label="End" onChange={setEnd} />}
      </section>
    </>
  );
};

export default DatePick;
