import { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import ProjectsList from "../components/ProjectsList";
import useQuery from "../hooks/useQuery";
import { Box, Skeleton } from "@mui/material";
import AuthContext from "../context/AuthContext";
import { authTypes } from "../types/AuthTypes";
import TopBar from "../components/TopBar";
import DeleteProject from "../components/DeleteProject";

const Home = () => {
  const { getData } = useQuery();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState(null);
  const [deleteView, setDeleteView] = useState({ active: false, id: null });
  const { dispatch } = useContext(AuthContext);
  const getProjects = async () => {
    const res = await getData("projects", localStorage.token);
    if (res.error) {
      localStorage.removeItem("token");
      dispatch({ type: authTypes.logout });
    }
    setProjects(res.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getProjects();
  }, []);
  useEffect(() => {
    getProjects();
  }, [deleteView]);
  
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems:"center",
              padding: "5% 10%",
              marginTop:"4rem",
              flexDirection:"column"
            }}
          >
            <Skeleton
              variant="rectangular"
              width={450}
              height={180}
              sx={{ borderRadius: "20px", marginBottom:"10px" }}
            />
         
           
          </Box>
        ) : (
          <>
            {projects !== null && (
              <ProjectsList projects={projects} setProjects={setProjects} setDeleteView={setDeleteView} />
            )}
          </>
        )}
      </section>
      {deleteView.active && (
        <DeleteProject deleteView={deleteView} setDeleteView={setDeleteView} />
      )}
    </>
  );
};

export default Home;
