import { Button } from "@mui/material";
import { useState } from "react";
import SignaturePad from "./SignaturePad";
import useQuery from "../hooks/useQuery";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from "@mui/icons-material/Delete";
import { dateToAMD } from "../helpers/general";

const EmployeesGrid = (props) => {
  const { attendances, setAttendanceId, attendanceId, setError, setLoading } =
    props;
  const { add, edit, setEdit, setEmployeeId, loading, getInitialData } = props;
  const [modal, setModal] = useState(false);
  const [signImg, setSignImg] = useState("");

  const { deleteData } = useQuery();
  const checkSign = (e) => {
    setSignImg(`../${e.target.id}`);
    setModal(true);
  };
  const deleteRow = async (id) => {
    const data = {
      id: parseInt(id),
    };
    const deletePost = await deleteData(
      "employeeAttendance",
      data,
      localStorage.token
    );
    if (deletePost.error === false) {
      setLoading(true);
      getInitialData();
    }
  };

  return (
    <section className="data" title="test">
      <section className="info">
        <h2 className="header_title">Date</h2>
        <h2 className="header_title">Name</h2>
        <h2 className="header_title">Code</h2>
        <h2 className="header_title">Start</h2>
        <h2 className="header_title">End</h2>
        <h2 className="header_title">Breaks</h2>
        <h2 className="header_title">Total</h2>
        <h2 className="header_title">Signature</h2>
        <h2 className="header_title">Status</h2>
        <h2 className="header_title">Edit</h2>
        <h2 className="header_title">Delete</h2>
      </section>

      {attendances.map((data) => {
        return (
          <section className="info" key={attendances.indexOf(data)}>
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h2 className="info_name">{dateToAMD(data.attendance_date)}</h2>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h2 className="info_name">{data.name_employee}</h2>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.cod}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.start}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.end !== "00:00:00" ? data.end : "" || ""}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>
                {data.break_time !== "00:00:00" ? data.break_time : "" || ""}
              </h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.total_time || ""}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <p
                onClick={checkSign}
                className="info_status completed"
                id={data.signature_employee}
              >
                Check
              </p>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <p
                className={`info_status ${
                  data.end === "00:00:00" ? "started" : "completed"
                } `}
              >
                {data.end !== "00:00:00" || !data.completed
                  ? "Registered"
                  : "Completed"}{" "}
              </p>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <Button
                variant="outlined"
                id={data.id_attendance}
                size="small"
                onClick={(e) => {
                  setAttendanceId(e.target.id);
                  setEdit(true);
                }}
              >
                EDIT
              </Button>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 10px",
                }}
              />
            ) : (
              <DeleteIcon
                id={data.id_attendance}
                onClick={(e) => {
                  let id_to_delete;
                  if (e.target.tagName === "path") {
                    id_to_delete = e.target.parentNode.id;
                  } else {
                    id_to_delete = e.target.id;
                  }

                  deleteRow(id_to_delete);
                }}
                sx={{ color: "tomato" }}
                className="delete_icon"
              />
            )}
          </section>
        );
      })}
      {modal && (
        <SignaturePad signImg={signImg} modal={modal} setSign={setModal} />
      )}
    </section>
  );
};

export default EmployeesGrid;
