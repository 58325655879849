import { useNavigate } from "react-router-dom";
import MyButton from "./MyButton";
import { useContext, useEffect } from "react";
import UserContext from "../context/UserContext";

const ProjectCard = (props) => {
  const { data, setDeleteView } = props;
  const navigate = useNavigate();
  const { permission } = useContext(UserContext);

  return (
    <section className="project_card">
      <div>
        <h3 className="title">{data.project_name}</h3>
        <h2 className="number">#{data.project_number}</h2>
        <h2 className="number">{data.location}</h2>
      </div>
      <MyButton
        text="MORE"
        className="action"
        onClick={() => {
          navigate(`/projects/${data.id}`);
        }}
      />
      {permission.permissions === "administrator" && (
        <div className="edit_section">
          <MyButton
            text="EDIT"
            className="edit"
            onClick={() => {
              navigate(`/edit_project/${data.id}`);
            }}
          />
          <MyButton
            text="DELETE"
            className="logout"
            onClick={() => {
              setDeleteView({active:true, id:data.id});
            }}
            
          />
        </div>
      )}
    </section>
  );
};

export default ProjectCard;
