import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { breakFraction, employeeTypes, mapEmployeeType } from "../helpers/data";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { checkEndTime, formatStartTime } from "../helpers/general";

const EditEmployee = (props) => {
  const [employeeData, setEmployeeData] = useState({
    name_employee: "",
    start: "",
    cod: "",
  });

  const [endTime, setEndTime] = useState({ status: null, value: "" });
  const [breaks, setBreaks] = useState("");

  const { setEdit, attendanceId, setError } = props;
  const { getData, putData } = useQuery();

  const editEmployee = async () => {
    if (
      employeeData.name_employee === "" ||
      employeeData.cod === "" ||
      breaks === "" ||
      !endTime.status
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }

    const data = {
      name_employee: employeeData.name_employee,
      start: employeeData.start,
      id_code: mapEmployeeType(employeeData.cod),
      end: endTime.value,
      break_time: parseFloat(breaks),
      id: attendanceId,
    };
    const post = await putData(`employeeAttendance`, data, localStorage.token);
   setEdit(false);
  };
  const getAttendanceInfo = async () => {
    const info = await getData(
      `employeeAttendance/${attendanceId}`,
      localStorage.token
    );
    const { name_employee, start, cod } = info.data.data.data[0];
    setEmployeeData({
      name_employee: name_employee,
      start: formatStartTime(start),
      cod: cod,
    });
  };

  const validateInput = (e) => {
    if (e.target.id === "end-time") {
      let status = checkEndTime(e.target.value);
      if (status === false) {
        setEndTime({ ...endTime, status: false });
      } else {
        setEndTime({ ...endTime, status: true });
      }
    }
  };

  useEffect(() => {
    getAttendanceInfo();
  }, []);

  return (
    <>
      <section className="create create_complete">
        <h3>Close employee assistance</h3>
        <p>Please complete or edit the rest of the fields</p>
        <TextField
          className="input_form"
          id="project_name"
          label="Employee Name"
          variant="outlined"
          value={employeeData.name_employee || ""}
          onChange={(e) => {
            setEmployeeData({
              ...employeeData,
              name_employee: e.target.value,
            });
          }}
        />
        <TextField
          id="project_number"
          label="Start Time"
          variant="outlined"
          className="input_form"
          value={employeeData.start || ""}
          onChange={(e) => {
            setEmployeeData({ ...employeeData, start: e.target.value });
          }}
        />

        <TextField
          label="End Time"
          variant="outlined"
          className="input_form"
          id="end-time"
          error={!endTime.status}
          onChange={(e) => {
            setEndTime({ ...endTime, value: e.target.value });
          }}
          onKeyUp={validateInput}
          value={endTime.value || ""}
        />
        <FormControl fullWidth className="input_form">
          <InputLabel id="breaks">Breaks</InputLabel>
          <Select
            labelId="break"
            id="break"
            label="break"
            value={breaks}
            //readOnly
            onChange={(e) => setBreaks(e.target.value)}
          >
            {breakFraction.map((valor) => {
              return (
                <MenuItem value={valor.time} key={valor.time}>
                  {valor.time}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth className="input_form">
          <InputLabel id="project-manager">Code</InputLabel>
          <Select
            labelId="project-manager"
            id="project_manager"
            label="project-manager"
            value={employeeData.cod || ""}
            onChage={(e) => {
              setEmployeeData({ ...employeeData, cod: e.target.value });
              console.log(e.target.value);
            }}
            // readOnly
          >
            {employeeTypes.map((persona) => {
              return (
                <MenuItem value={persona.type} key={persona.type}>
                  {persona.type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={editEmployee}>
          COMPLETE
        </Button>
      </section>
    </>
  );
};
export default EditEmployee;
