import { dateToAMD, isMonday, isSunday, SERVER_URL } from "../helpers/general";
import axios from "axios";

import { useEffect, useReducer, useState } from "react";
import MyButton from "./MyButton";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, TextField } from "@mui/material";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import DatePick from "./DatePick";
import { InputReducer } from "../reducers/InputReducer";
import { generatePDF } from "../helpers/pdfGen";

let blobsArray = [];

const FullInvoice = () => {
  const [invoiceType, setInvoiceType] = useState("week");
  const [projectCheckboxes, setProjectCheckboxes] = useState({});

  const facturaReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_DATA":
        return {
          data: action.payload,
        };

      default:
        return state;
    }
  };
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [facturaData, facturaDispatch] = useReducer(facturaReducer, {
    data: null,
  });

  const [range, setRange] = useState({ date_start: null, date_end: null });
  const [sending, setSending] = useState(false);
  const [projects, setProjects] = useState([]);
  const { getData, postData } = useQuery();
  const [projectsData, dispatch] = useReducer(InputReducer, {});
  const [invoices, setInvoices] = useState();

  let projectsToInvoice = [];
  let projectTitles = [];
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const handleInvoiceType = (e) => {
    setInvoiceType(e.target.value);
  };
  const setStart = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_start: newDate });
  };

  const setEnd = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_end: newDate });
  };
  const setProjectsArray = (e) => {
    if (e.target.checked) {
      projectsToInvoice.push(e.target.id);
    } else {
      const index = projectsToInvoice.indexOf(e.target.id);
      if (index !== -1) {
        projectsToInvoice.splice(index, 1);
      }
    }
  };
  const getProjectsToCheck = async () => {
    setProjects([]);
    setError({ error: false, severity: "", message: "" });

    let endpoint;
    if (invoiceType === "day") {
      if (!range.date_start) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date",
        });
        return;
      }
      endpoint = `invoiceDailyprojects/${range.date_start}`;
    } else {
      endpoint = `invoiceWeeklyprojects/${range.date_start}/${range.date_end}`;
      if (!range.date_start || !range.date_end) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date range",
        });
        return;
      }
      const mondayTrue = isMonday(range.date_start);
      const sundayTrue = isSunday(range.date_end);
      if (!mondayTrue || !sundayTrue) {
        setError({
          status: true,
          message:
            "Start date must be a monday and end date must be a sunday, please check",
          severity: "error",
        });
        return;
      }
    }

    setProjects([]);

    const projects = await getData(endpoint, localStorage.token);
    if (projects.data.status === 201) {
      setError({
        status: true,
        message: "There's no projects to invoice",
        severity: "error",
      });
      setSending(false);

      return;
    }
    setProjects(projects.data.data);
    // return FullAttendances;
  };

  const getInvoices = async () => {
    let body;
    let endpoint;
    projectsToInvoice = Object.keys(projectCheckboxes).filter(
      (clave) => projectCheckboxes[clave]
    );
    setProjectCheckboxes({});
    if (invoiceType === "day") {
      endpoint = "invoiceDaily";
      body = {
        date_invoice: range.date_start,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    } else {
      endpoint = "invoiceWeekly";
      body = {
        date_start: range.date_start,
        date_end: range.date_end,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    }

    const invoicesToPrint = await postData(endpoint, body, localStorage.token);

     facturaDispatch({
      type: "CHANGE_DATA",
      payload: invoicesToPrint.data.data.data,
    }); 
  };

  const mapProjectsToPDF = (fact) => {
    const mainArray = Object.values(facturaData.data)[0];
    projectTitles = Object.keys(mainArray.projects);
    const projects = Object.values(mainArray.projects);
    projects.map((project, index) => {
      generatePDF(
        project,
        index,
        invoiceType,
        projectTitles,
        range,
        facturaData,
        setProjects
      ); 
    });
  };
  const selectAllProjects = (e) => {
    const newProjectCheckboxes = {};
    for (const project of projects) {
      newProjectCheckboxes[project.id] = e.target.checked;
    }
    setProjectCheckboxes(newProjectCheckboxes);
  };

  const sendProjectsToServer = async (blobsArray) => {
    try {
      const formData = new FormData();
      blobsArray.forEach((item, index) => {
        // Agregar el blob (factura)
        formData.append(`invoices[${index}][invoice]`, item.invoice);

        // Agregar los otros datos (proyecto, total, fecha)
        formData.append(`invoices[${index}][project]`, item.project);
        formData.append(`invoices[${index}][total]`, item.total);
        formData.append(`invoices[${index}][date]`, item.date);
      });

      // Configurar el envío
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`, // Usar getItem para acceder al token
        },
      };
      // Enviar la solicitud con Axios utilizando async/await
      const response = await axios.post(
        `${SERVER_URL}/uploadInvoiceAttendance`,
        formData,
        config
      );
      console.log("PDFs enviados con éxito:", response.data);
    } catch (error) {
      console.error("Error al enviar los PDFs:", error);
    }
  };

  useEffect(() => {
    if (facturaData.data) {
      mapProjectsToPDF();
    }
  }, [facturaData.data]);

  return (
    <>
      <Navbar />
      <TopBar />
      <section className="report create shape" style={{ gridRow: "2/4" }}>
        <section className="content content_report">
          <h2>Invoices</h2>

          <DatePick
            setStart={setStart}
            setEnd={setEnd}
            day={invoiceType === "day"}
          />
          {!sending ? (
            <MyButton
              text="Get Projects"
              className="add mb-10"
              onClick={() => {
                getProjectsToCheck();
              }}
            />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}

          <MyButton text="Go Back" className="logout" onClick={() => {}} />
          <section className="selectors" style={{ marginTop: "2rem" }}>
            <div className="check">
              <input
                type="radio"
                name="invoiceType"
                value="week"
                checked={invoiceType === "week"}
                onChange={handleInvoiceType}
              />
              <label> Weekly Invoice</label>
            </div>
            <div className="check">
              <input
                type="radio"
                name="invoiceType"
                value="day"
                onChange={handleInvoiceType}
                checked={invoiceType === "day"}
              />

              <label> Daily Invoice</label>
            </div>
          </section>
        </section>
        <section className="pt_invoice">
          {projects.length > 0 && (
            <>
              <h2>Type the starting invoice number</h2>
              <TextField
                className="input_form"
                id="invoice_number"
                label="Ex. 1000"
                variant="outlined"
                value={invoiceNumber}
                onChange={(e) => {
                  setInvoiceNumber(e.target.value);
                }}
              />
              <h2>Select projects to invoice</h2>
              <div className="check_project shape">
                <input
                  type="checkbox"
                  id="select_all"
                  onChange={selectAllProjects}
                />
                <h5 className="title">"Select All"</h5>
              </div>
              <div className="check_container">
                {projects.map((proyecto) => {
                  return (
                    <div key={proyecto.id} className="check_project shape">
                      <input
                        type="checkbox"
                        checked={projectCheckboxes[proyecto.id] || false}
                        id={proyecto.id}
                        onChange={(e) => {
                          setProjectsArray(e);
                          setProjectCheckboxes((prevCheckboxes) => ({
                            ...prevCheckboxes,
                            [proyecto.id]: e.target.checked,
                          }));
                        }}
                      />
                      <h5 className="title">{proyecto.project_name}</h5>
                    </div>
                  );
                })}
              </div>

              {!sending ? (
                <MyButton
                  text="Get Invoice"
                  className={`add mb-10`}
                  onClick={() => {
                    setError({
                      status: false,
                      message: "",
                      severity: "",
                    });
                    if (projectsToInvoice.length > 0 || invoiceNumber !== "") {
                      getInvoices();
                    } else {
                      setError({
                        status: true,
                        message:
                          "You must type the invoice number and select at least one project",
                        severity: "error",
                      });
                      return;
                    }
                  }}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ alignSelf: "center" }}
                />
              )}
            </>
          )}
        </section>
        {error.status && (
          <Alert
            //  className="project_alert"
            severity={error.severity}
            sx={{ marginTop: "1rem" }}
          >
            {error.message}
          </Alert>
        )}
      </section>
    </>
  );
};

export default FullInvoice;
