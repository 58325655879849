import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  headerB: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    textAlign: "right",
    marginBottom: 20,
    color: "#F87035",
  },
  logo: {
    width: 50,
    height: 50,
  },
  invoiceTitle: {
    fontSize: 25,
    marginBottom: 10,
    textAlign: "center",
    color: "#F87035",
  },
  tableHeader: {
    fontSize: 12,
    backgroundColor: "#F87035",
    color: "white",
    padding: 5,
    marginBottom: 10,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
  },
  footer: {
    textAlign: "center",
    fontSize: 12,
    color: "#F87035",
  },
});

// Función principal de la factura
const InvoicePDF = ({ invoiceData, projects }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Cabecera */}
        <View style={styles.section}>
          <Text style={styles.header}>
            INVOICE # {invoiceData.invoiceNumber}
          </Text>
          <Text>Date: {invoiceData.date}</Text>
        </View>

        {/* Sección Bill To */}
        <View style={styles.headerB}>
          <Text>BILL TO:</Text>
          <Text>{invoiceData.client.name}</Text>
          <Text>{invoiceData.client.address}</Text>
          <Text>{invoiceData.client.email}</Text>
        </View>

        {/* Tabla de proyectos */}
        <View>
          <Text style={styles.invoiceTitle}>Invoice Details</Text>

          {/* Encabezado de la tabla */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCell}>Project Name</Text>
            <Text style={styles.tableCell}>QTY</Text>
            <Text style={styles.tableCell}>Unit Price</Text>
            <Text style={styles.tableCell}>Amount</Text>
          </View>

          {/* Filas de proyectos */}
          {projects.map((project, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{project.name}</Text>
              <Text style={styles.tableCell}>{project.qty}</Text>
              <Text style={styles.tableCell}>${project.unitPrice}</Text>
              <Text style={styles.tableCell}>${project.amount}</Text>
            </View>
          ))}
        </View>

        {/* Totales */}
        <View style={styles.section}>
          <Text style={styles.invoiceTitle}>Total: ${invoiceData.total}</Text>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>Thanks for your business!</Text>
          <Text>
            If you have any questions, contact us at: admin@company.com
          </Text>
        </View>
      </Page>
    </Document>
  );
};

// Componente para mostrar el PDF
const InvoiceViewer = () => {
  const invoiceData = {
    invoiceNumber: "1001",
    date: "09/24/2024",
    client: {
      name: "First Onsite",
      address: "3401 Quorum Drive, Suite 300, Fort Worth, TX 76137",
      email: "Ap@firstonsite.com",
    },
    total: 5000,
  };

  const projects = [
    { name: "Project Alpha", qty: 10, unitPrice: 50, amount: 500 },
    { name: "Project Beta", qty: 20, unitPrice: 100, amount: 2000 },
    { name: "Project Gamma", qty: 1, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 2, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 3, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 4, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 5, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 6, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 7, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 8, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 9, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 10, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 11, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 12, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 13, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 14, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 15, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 16, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 17, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 18, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 19, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 20, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 21, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 22, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 23, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 24, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 25, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 26, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 27, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 28, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 29, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 30, unitPrice: 150, amount: 2250 },
    { name: "Project Alpha", qty: 10, unitPrice: 50, amount: 500 },
    { name: "Project Beta", qty: 20, unitPrice: 100, amount: 2000 },
    { name: "Project Gamma", qty: 1, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 2, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 3, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 4, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 5, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 6, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 7, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 8, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 9, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 10, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 11, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 12, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 13, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 14, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 15, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 16, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 17, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 18, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 19, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 20, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 21, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 22, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 23, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 24, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 25, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 26, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 27, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 28, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 29, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 30, unitPrice: 150, amount: 2250 },
    { name: "Project Alpha", qty: 10, unitPrice: 50, amount: 500 },
    { name: "Project Beta", qty: 20, unitPrice: 100, amount: 2000 },
    { name: "Project Gamma", qty: 1, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 2, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 3, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 4, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 5, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 6, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 7, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 8, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 9, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 10, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 11, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 12, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 13, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 14, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 15, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 16, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 17, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 18, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 19, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 20, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 21, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 22, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 23, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 24, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 25, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 26, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 27, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 28, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 29, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 30, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 18, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 19, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 20, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 21, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 22, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 23, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 24, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 25, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 26, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 27, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 28, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 29, unitPrice: 150, amount: 2250 },
    { name: "Project Gamma", qty: 30, unitPrice: 150, amount: 2250 },
  ];

  return (
    <PDFViewer style={{ width: "100%", height: "90vh" }}>
      <InvoicePDF invoiceData={invoiceData} projects={projects} />
    </PDFViewer>
  );
};

export default InvoiceViewer;
