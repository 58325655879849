import { useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import { Divider } from "@mui/material";
import AddNewProject from "./AddNewProject";
import MyButton from "./MyButton";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClearAllIcon from '@mui/icons-material/ClearAll';

const ProjectsList = (props) => {
  const { projects, setProjects, setDeleteView } = props;
  const [filterView, setFilterView] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [sortOption, setSortOption] = useState("asc");

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const filterProjects = (query) => {
    const filtered = projects.filter((project) => {
      return (
        project.project_name.toLowerCase().includes(query) ||
        project.project_number.toString().includes(query) ||
        project.location.toLowerCase().includes(query)
      );
    });
    setFilteredProjects(filtered);
  };

  const filter = (e) => {
    const query = e.target.value.toLowerCase();
    filterProjects(query);
  };

  const sortProjects = () => {
    const sortedProjects = [...projects];

    sortedProjects.sort((a, b) => {
      if (sortOption === "asc") {
        return sortedProjects.indexOf(a) - sortedProjects.indexOf(b);
      } else if (sortOption === "desc") {
        return sortedProjects.indexOf(b) - sortedProjects.indexOf(a);
      } else {
      return 0;
      }
    });
    setFilteredProjects(sortedProjects);
  };

  const handleSortOptionChange = (e) => {
    setSortOption(e.target.value);
  };

  const clearFilter = () => {
    setFilteredProjects(projects);
  };

  const navigate = useNavigate();
  return (
    <section className="project_list">
      <section className="project_list-header">
        <h2>Projects</h2>
        <div className="header_buttons">
          <button
            className="filter"
            onClick={() => {
              setFilterView(!filterView);
            }}
          >
            Filters <FilterListIcon />
          </button>

          <MyButton
            text="Create project"
            className="add"
            onClick={() => {
              navigate("/create_project");
            }}
          />
        </div>
      </section>
      <Divider />
      {
        <section className="list">
          {filteredProjects.map((project) => {
            return (
              <ProjectCard
                data={project}
                key={project.id}
                setDeleteView={setDeleteView}
              />
            );
          })}
        </section>
      }
      {filterView && (
        <section className="filter_box shape">
          <div className="searchbar">
            <button className="filter" onClick={() => clearFilter()}>
              Clear <ClearAllIcon />
            </button>
            <h4>Type a keyword</h4>
            <span>(i.e. Project Name, Number, Location)</span>
            <input
              type="text"
              onChange={(e) => {
                filter(e);
              }}
            />
          </div>
          <h4>Sort by date</h4>
          <div className="sort">
            <label htmlFor="asc">Descending (newest first)</label>
            <input
              type="radio"
              name="date"
              value="asc"
              id="date"
              checked={sortOption === "asc"}
              onChange={handleSortOptionChange}
            />
          </div>
          <div className="sort">
            <label htmlFor="desc">Ascending(oldest first)</label>
            <input
              type="radio"
              name="date"
              value="desc"
              id="date"
              checked={sortOption === "desc"}
              onChange={handleSortOptionChange}
            />
          </div>
          <button className="filter" onClick={sortProjects}>
            Order
          </button>
        </section>
      )}
      <Divider />
    </section>
  );
};

export default ProjectsList;
