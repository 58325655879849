import { useContext, useEffect, useReducer, useState } from "react";
import AuthContext from "../context/AuthContext";
import useQuery from "../hooks/useQuery";
import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
  const [sending, setSending] = useState(false);
  const permissions = [
    {
      id: 0,
      description: "Set user's permission",
    },
    { id: 3, description: "administrator" },
    {
      id: 4,
      description: "generic",
    },
  ];
  const initialState = {
    name: "",
    lastname: "",
    username: "",
    password: "",
    permission: 0,
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { dispatch } = useContext(AuthContext);
  const [error, setError] = useState({ status: false, message: "" });
  const { postData } = useQuery();

  const register = async () => {
    if (
      inputData.username === "" ||
      inputData.password === "" ||
      inputData.name === "" ||
      inputData.lastName === "" ||
      inputData.permission === 0
    ) {
      setError({
        status: true,
        message: "You must enter all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      name: inputData.name,
      lastname: inputData.lastname,
      username: inputData.username,
      password: inputData.password,
      permission: inputData.permission,
    };
    const response = await postData("users", data, localStorage.token);
    if (response.data.status === 200) {
      setError({ status: true, message: "User created", severity: "success" });
      setSending(false)
    }
  };

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Create User</h2>
        <p className="ml-20">Please fill all the required information</p>
        <section className="create create_project shape">
          <TextField
            className="input_form"
            id="name"
            label="Name"
            variant="outlined"
            name="name"
            onChange={handleInputChange}
            value={inputData.name}
          />
          <TextField
            className="input_form"
            id="lastname"
            label="Lastname"
            variant="outlined"
            name="lastname"
            onChange={handleInputChange}
            value={inputData.lastname}
          />
          <TextField
            className="input_form"
            id="user"
            label="Username"
            variant="outlined"
            name="username"
            onChange={handleInputChange}
            value={inputData.username}
          />
          <TextField
            className="input_form"
            id="password"
            label="Password"
            name="password"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.password}
          />
          <FormControl fullWidth className="input_form">
            <InputLabel>Permission</InputLabel>
            <Select
              labelId="permission"
              value={inputData.permission}
              name="permission"
              label="permission"
              onChange={handleInputChange}
              id="permission"
            >
              {permissions?.map((permission) => {
                return (
                  <MenuItem key={permission.id} value={permission.id}>
                    {permission.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!sending ? (
            <MyButton
              onClick={register}
              text="CREATE USER"
              className="action"
            />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
          {error.status && (
            <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
              {error.message}
            </Alert>
          )}
        </section>
      </section>
    </>
  );
};

export default CreateUser;
