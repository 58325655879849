import { Alert, TextField } from "@mui/material";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import { authTypes } from "../types/AuthTypes";
import useQuery from "../hooks/useQuery";
import InputAdornment from "@mui/material/InputAdornment";
import MyButton from "../components/MyButton";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UserContext from "../context/UserContext";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(AuthContext);
  const { UserDispatch } = useContext(UserContext);
  const [error, setError] = useState({ status: false, message: "" });
  const [sending, setSending] = useState(false);
  const [visible, setVisible] = useState(false);
  const { postData } = useQuery();

  const login = async () => {
    setError({ status: false, message: "" });
    if (password === "" && username === "") {
      setError({ status: true, message: "You must enter your credentials" });
      return;
    }
    setSending(true);
    const data = {
      username,
      password,
    };
    const response = await postData("login", data);
    if (response.data.status === 200) {
      console.log(response.data);
      localStorage.setItem("currentUser", response.data.data.name);
      localStorage.setItem("id_current_user", response.data.data.id_current_user);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("level", response.data.data.permissions[0]);
      UserDispatch({
        type: "SET_INFO",
        permissions: response.data.data.permissions[0],
      });
     
      dispatch({ type: authTypes.login });
    } else {
      setError({
        status: true,
        message: response?.data?.response?.data.message || "Unknown Error",
      });
      setSending(false);
    }
  };

  return (
    <section className="login">
      <img src="../assets/logo.png" alt="logo" />
      <TextField
        className="input_form"
        id="user"
        label="Username"
        variant="outlined"
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        value={username}
      />

      <TextField
        className="input_form"
        id="password"
        label="Password"
        type={!visible ? "password" : "text"}
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        value={password}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setVisible(!visible);
              }}
              position="end"
            >
              {!visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </InputAdornment>
          ),
        }}
      />
      {sending ? (
        <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
      ) : (
        <MyButton onClick={login} className="action" text="Login" />
      )}
      {error.status && (
        <Alert severity="error" sx={{ marginTop: "1rem" }}>
          {error.message}
        </Alert>
      )}
    </section>
  );
};

export default Login;
