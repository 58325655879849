import { useRef } from "react";
import { Button } from "@mui/material";
import ReactSignatureCanvas from "react-signature-canvas";
import MyButton from "./MyButton";
const SignaturePad = (props) => {
  const pad = useRef();
  const clearSign = () => {
    pad.current.clear();
  };
  const { setSign, signImg, inputDispatch } = props;
  // const [img, setImg] = useState(null);
  const createSign = () => {
    const URL = pad.current.getTrimmedCanvas().toDataURL("image/png");
   inputDispatch({
    type: "CHANGE_FIELD",
    fieldName: "signature_employee",
    fieldValue: URL,
  });
    setSign(false);
  };
  const closeSignPad = () => {
    setSign(false);
  };

  return (
    <section className="pad_parent">
      <section className="pad_container">
        {!signImg ? (
          <ReactSignatureCanvas
            penColor="black"
            canvasProps={{ className: "sign_pad" }}
            ref={pad}
          />
        ) : (
          <img className="sign_made" src={signImg} />
        )}
        <section className="sign_buttons">
          <MyButton className="action" text="Close" onClick={closeSignPad} />
          {!signImg && (
            <>
              <Button className="clear_sign" onClick={clearSign}>
                Clear
              </Button>
              <MyButton className="action" text="Submit" onClick={createSign} />
            </>
          )}
        </section>
      </section>
    </section>
  );
};

export default SignaturePad;
