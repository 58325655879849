import { createContext, useEffect, useReducer } from "react";
import { UserReducer } from "../reducers/UserReducer";

const UserContext = createContext();
const init = () => {
  return (
    { permissions: localStorage.getItem("level") } || {
      permissions: null,
    }
  );
};
const UserProvider = ({ children }) => {
  const [permission, UserDispatch] = useReducer(UserReducer, {}, init);
  return (
    <UserContext.Provider value={{ permission, UserDispatch }}>
      {children}
    </UserContext.Provider>
  );
};
export { UserProvider };
export default UserContext;
