import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import MyButton from "./MyButton";
import { authTypes } from "../types/AuthTypes";

const TopBar = () => {
  const logout = () => {
    localStorage.removeItem("token");
    dispatch({ type: authTypes.logout });
  };
  const { dispatch } = useContext(AuthContext);
  return (
    <section className="top_bar">
      <img src="../assets/logo.png" alt="logo"  />
      <div className="right">
        <h3>Hello, {localStorage.currentUser}</h3>
        <MyButton text="Logout" className="logout" onClick={logout} />
      </div>
    </section>
  );
};

export default TopBar;
