import { Link } from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PeopleIcon from "@mui/icons-material/People";
import { useContext, useEffect, useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UserContext from "../context/UserContext";
const Navbar = () => {
  const getCurrentDimension = () => {
    return { width: window.innerWidth };
  };
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [expand, setExpand] = useState(false);
  const { permission } = useContext(UserContext);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <nav
      className={`${screenSize.width < 1200 ? "mini" : ""} ${
        expand ? "expanded" : ""
      }`}
    >
      {!expand ? (
        <MenuOpenIcon
          className="nav_button open"
          onClick={() => {
            setExpand(true);
          }}
        />
      ) : (
        <CloseIcon
          className="nav_button"
          onClick={() => {
            setExpand(false);
          }}
        />
      )}
      <ul className="main_ul">
        <li>
          <Link to="/">
            <AppRegistrationIcon /> Projects
          </Link>
        </li>
       
        <li>
          <Link to="/invoices">
            <ReceiptIcon /> Invoices
          </Link>
        </li>
        <li>
        
        <AssessmentIcon /> Reports
   
    </li>
    <li>
      <ul className="secondary_ul">
        <li>
          <Link to="/report">Excel Report</Link>
        </li>
        <li>
          <Link to="/attendance_report">Attendance Report</Link>
        </li>
      </ul>

    </li>
        <li>
          <PeopleIcon /> Users
        </li>
        <li>
          <ul className="secondary_ul">
            <li>
              <Link
                to={permission.permissions === "generic" ? "/" : "/create"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Add User
              </Link>
            </li>
            <li>
              <Link
                to={permission.permissions === "generic" ? "/" : "/delete_user"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Delete User
              </Link>
            </li>
            <li>
              <Link to="/register">External Project Leaders</Link>
            </li>
          </ul>
        </li>
       
      </ul>
    </nav>
  );
};

export default Navbar;
