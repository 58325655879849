import { AuthProvider } from "./context/AuthContext";
import LoginRouter from "./router/LoginRouter";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./context/ThemeContext";
import { UserProvider } from "./context/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <LoginRouter />
          </LocalizationProvider>
        </ThemeProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
