import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRouter from "./AppRouter";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import Login from "../views/Login";

const LoginRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          index
          path="/login"
          element={
            <PublicRouter>
              <Login />
            </PublicRouter>
          }
        ></Route>
        {
          <Route
            path="/*"
            element={
              <PrivateRouter>
                <AppRouter />
              </PrivateRouter>
            }
          />
        }
      </Routes>
    </Router>
  );
};

export default LoginRouter;
