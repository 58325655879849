import { useContext, useEffect, useReducer, useState } from "react";
import AuthContext from "../context/AuthContext";
import useQuery from "../hooks/useQuery";
import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";
import { useParams } from "react-router-dom";

const DeleteUser = () => {
  const [users, setUsers] = useState([]);
  const [sending, setSending] = useState(false);
  const initialState = {
    user: 0,
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { dispatch } = useContext(AuthContext);
  const [error, setError] = useState({ status: false, message: "" });
  const { deleteData, getData } = useQuery();

  const deleteUser = async () => {
    if (inputData.user === 0) {
      setError({
        status: true,
        message: "Please select a user",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      id: inputData.user,
      id_current_user: localStorage.id_current_user,
    };
    const response = await deleteData("users", data, localStorage.token);
    if (response.error === true && response.data.response.status === 401) {
      setError({
        status: true,
        message: "You cannot delete yourself",
        severity: "error",
      });
    }
    if (response.data.status === 200) {
      setError({ status: true, message: "User Deleted", severity: "success" });
    }
    setSending(false)
    
  };

  const getInitialData = async () => {
    const data = await getData("users", localStorage.token);
    if (data.data.status === 200) {
      //quito el superadmin de la lista de usuarios a eliminar y seteo users con todos los demas
      const usersWithoutAdmin = data.data.data.slice(1);
      //  const newArray = usersWithoutAdmin.filter(id_user=> id_user!==id)
      if (usersWithoutAdmin.length > 0) {
        setUsers(usersWithoutAdmin);
      }
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Delete User</h2>
        <p className="ml-20">Please select an user from the list</p>
        <section className="create create_project shape">
          <FormControl fullWidth className="input_form">
            <InputLabel>User</InputLabel>
            <Select
              labelId="user"
              value={inputData.user || ""}
              name="user"
              label="user"
              onChange={handleInputChange}
              id="user"
            >
              {users?.map((dato) => {
                return (
                  <MenuItem key={dato.id} value={dato.id}>
                    {dato.name} {dato.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {sending ? (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          ) : (
            <MyButton
              onClick={deleteUser}
              text="DELETE USER"
              className="action"
            />
          )}

          {error.status && (
            <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
              {error.message}
            </Alert>
          )}
        </section>
      </section>
    </>
  );
};

export default DeleteUser;
