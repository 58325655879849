
export const InputReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_FIELD':
        return {
          ...state,
          [action.fieldName]: action.fieldValue,
        };
       
     
      default:
        return state;
    }
  };