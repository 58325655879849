import { useState } from "react";
import useQuery from "../hooks/useQuery";
import MyButton from "./MyButton";
import { Alert, CircularProgress } from "@mui/material";

const DeleteProject = (props) => {
  const { deleteView, setDeleteView } = props;
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const { deleteData } = useQuery();
  const deleteProject = async () => {
    setSending(true);
    const deletedProject = await deleteData(
      "projects",
      { id: deleteView.id },
      localStorage.token
    );
    if (deletedProject.error === true) {
      setError({
        status: true,
        message: deletedProject.data.response.data.message,
        severity: "error",
      });
      setSending(false);
      return;
    }
    if (deletedProject.data.status === 200) {
      setSending(false);
      setDeleteView({ active: false, id: null });
    }
    setSending(false);
  };
  return (
    <section className="delete_project">
      <section className="delete_project-content">
        <h2>Please confirm you want to delete this project</h2>
        {error.status && (
          <Alert
            //  className="project_alert"
            severity={error.severity}
            sx={{ marginTop: "1rem" }}
          >
            {error.message}
          </Alert>
        )}

        {!sending ? (
          <section className="buttons">
            <MyButton
              text="CANCEL"
              className="edit"
              onClick={() => {
                setDeleteView({ active: false, id: null });
              }}
            />
            <MyButton
              text="DELETE"
              className="logout"
              onClick={deleteProject}
            />
          </section>
        ) : (
          <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
        )}
      </section>
    </section>
  );
};

export default DeleteProject;
