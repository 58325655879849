import Navbar from "../components/Navbar";
import ReportByDate from "../components/ReportByDate";
import TopBar from "../components/TopBar";

const PdfAttendanceReport = () => {
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content content_report">
        <h2>Attendance Report</h2>
        <ReportByDate type="PDF"/>
      </section>
    </>
  );
};

export default PdfAttendanceReport;
