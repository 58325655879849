import {
  Alert,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import TopBar from "../components/TopBar";
import Navbar from "../components/Navbar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";

const EditProject = () => {
  const { id } = useParams();
  const [supervisores, setSupevisores] = useState([]);
  const [sending, setSending] = useState(false);

  const [inputData, inputDispatch] = useReducer(InputReducer, {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { getData, putData } = useQuery();
  const navigate = useNavigate();
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const editProject = async () => {
    if (
      inputData.project_name === "" ||
      inputData.project_number === "" ||
      inputData.location === "" ||
      inputData.id_manager === "" ||
      inputData.id_supervisor === ""
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const newProjectData = {
      id: id,
      project_name: inputData.project_name,
      project_number: inputData.project_number,
      location: inputData.location,
      id_supervisor: inputData.id_supervisor,
      id_manager: inputData.id_manager,
    };
    const post = await putData("projects", newProjectData, localStorage.token);
    console.log(post.data.status)
    if (post.data.status === 200) {
      setSending(false);
      setError({
        error: true,
        severity: "success",
        message: "Project Updated",
      });
   
    }
  };

  const getInitialData = async () => {
    const supervisors = await getData("projectLeaders", localStorage.token);
    const projectData = await getData(`projectsById/${id}`, localStorage.token);
    const datos = projectData.data.data[0];
    const fieldsToChange = [
      "project_name",
      "project_number",
      "location",
      "id_manager",
      "id_supervisor",
    ];
    for (const fieldName of fieldsToChange) {
      inputDispatch({
        type: "CHANGE_FIELD",
        fieldName,
        fieldValue: datos[fieldName],
      });
    }
    if (supervisors.error === false) {
      setSupevisores(supervisors.data.data);
      return;
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Edit Project</h2>
        <p className="ml-20">Please fill all the required information</p>
        <section className="create create_project shape">
          <TextField
            className="input_form"
            label="Project Name"
            name="project_name"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.project_name || ""}
          />
          <TextField
            label="Project Number"
            name="project_number"
            variant="outlined"
            className="input_form"
            onChange={handleInputChange}
            value={inputData.project_number || ""}
          />
          <TextField
            label="Location"
            variant="outlined"
            className="input_form"
            name="location"
            onChange={handleInputChange}
            value={inputData.location || ""}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Project Supervisor
            </InputLabel>
            <Select
              labelId="project-supervisor"
              name="id_supervisor"
              label="project-supervisor"
              onChange={handleInputChange}
              value={inputData.id_supervisor || ""}
            >
              {supervisores?.map((persona) => {
                return (
                  <MenuItem key={persona.id} value={persona.id}>
                    {persona.complete_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth className="input_form">
            <InputLabel id="project-manager">Project Manager</InputLabel>
            <Select
              labelId="project-manager"
              label="project-manager"
              name="id_manager"
              onChange={handleInputChange}
              value={inputData.id_manager || ""}
            >
              {supervisores?.map((persona) => {
                return (
                  <MenuItem key={persona.id} value={persona.id}>
                    {persona.complete_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!sending ? (
            <MyButton className="add" text="Edit" onClick={editProject} />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
           {error.error && (
          <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
            {error.message}
          </Alert>
        )}
        </section>
      </section>
    </>
  );
};

export default EditProject;
