import {
  dateToAMD,
  dateToMDA,
  getDate,
  hhmm,
  limitChars,
} from "../helpers/general";
import { useEffect, useState } from "react";
import MyButton from "./MyButton";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import DatePick from "./DatePick";
import jsPDF from "jspdf";

const ReportByDate = (props) => {
  const navigate = useNavigate();
  const { projectId, setReportByDate, type } = props;
  const { getData, postData } = useQuery();
  const [sending, setSending] = useState(false);
  const [attendancesReport, setAttendancesReport] = useState([]);
  const [range, setRange] = useState({ date_start: null, date_end: null });
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });
  const setStart = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_start: newDate });
  };
  const setEnd = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_end: newDate });
  };

  const getAttendances = async (body) => {
    let endpoint;
    if (type === "full") {
      endpoint = "unifiedAttendance";
    }
    if (type === "PDF") {
      endpoint = "employeeAttendanceProject";
    }
    if (type === "byProject") {
      endpoint = "employeeAttendanceDate";
    }

    const attendances = await postData(endpoint, body, localStorage.token);
    if (attendances.data.status === 201) {
      return { status: "error", message: attendances.data.data.message };
    }
    setSending(false);
    return attendances;
  };

  const downloadExcel = () => {
    if (attendancesReport.length === 0) {
      return;
    }
    const rows = attendancesReport.map((attendance) => ({
      date: dateToAMD(attendance.attendance_date),
      project_number: attendance.project_number,
      project_name: attendance.project_name,
      name: attendance.name_employee,
      code: attendance.cod,
      start: attendance.start,
      end: attendance.end,
      breaks: attendance.break_time,
      total: attendance.total_time,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, `Attendances`);

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Date",
        "Project Number",
        "Project Name",
        "Name",
        "Code",
        "Start Time",
        "End Time",
        "Breaks",
        "Total",
      ],
    ]);
    if (type === "byProject") {
      XLSX.writeFile(
        workbook,
        `Project_${attendancesReport[0].project_name}_${range.date_start}_to_${range.date_end}.xlsx`,
        {
          compression: true,
        }
      );
    }
    if (type === "full") {
      XLSX.writeFile(workbook, `Projects_Report.xlsx`, {
        compression: true,
      });
    }
  };

  const downloadPDF = () => {
    if (attendancesReport.length === 0) {
      return;
    }
    attendancesReport.map((report) => {
      const pdf = new jsPDF("p", "mm", "a4", true);
      // Configuración del tamaño de texto y colores y ancho
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const sideMargin = 10;
      pdf.setFontSize(12);
      const orangeColor = [248, 112, 53];
      const whiteColor = [255, 255, 255];
      const blackColor = [0, 0, 0];
      const cellHeight = 8;
      const logoImg = new Image();
      logoImg.src = "../assets/logo.png";
      const logoWidth = 40;
      const logoHeight = 18;
      pdf.addImage(logoImg, "PNG", sideMargin, 5, logoWidth, logoHeight);

      pdf.setTextColor(...blackColor);
      pdf.setFontSize(8);
      pdf.text(
        `Project: ${report.project_name} # ${report.project_number}`,
        pageWidth - sideMargin,
        5 + logoHeight / 2,
        {
          align: "right",
        }
      );
      pdf.text(
        `Dates: ${dateToMDA(range.date_start)} to ${dateToMDA(range.date_end)}`,
        pageWidth - sideMargin,
        5 + logoHeight / 2 + cellHeight,
        {
          align: "right",
        }
      );

      // CABECERA
      pdf.setFillColor(...orangeColor);
      pdf.rect(
        sideMargin,
        logoHeight + 10,
        pageWidth - sideMargin * 2,
        cellHeight,
        "F"
      );
      const marginNumber = pageWidth / 4;
      const marginName = marginNumber + 20;

      pdf.setTextColor(...whiteColor);

      const columns = 8;

      const columnWidth = (pageWidth - sideMargin * 2) / columns;
      pdf.text(
        "Employee Name",
        sideMargin + 5,
        logoHeight + 11 + cellHeight / 2
      );

      pdf.text(
        "Type",
        sideMargin + 10 + columnWidth,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "Start Time",
        sideMargin + 5 + columnWidth * 2,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "End Time",
        sideMargin + 5 + columnWidth * 3,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "Breaks",
        sideMargin + 5 + columnWidth * 4,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "Total Time",
        sideMargin + 5 + columnWidth * 5,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "Signature",
        sideMargin + 5 + columnWidth * 6,
        logoHeight + 11 + cellHeight / 2
      );
      pdf.text(
        "Date",
        sideMargin + 5 + columnWidth * 7,
        logoHeight + 11 + cellHeight / 2
      );

      let yPosition = logoHeight + 25;
      pdf.setTextColor(...blackColor);
      pdf.setFillColor(...whiteColor);
      pdf.setFontSize(8);

      let currentPage = 1;
      let printingItem = 1;
      const dataToPrint = report.data;

      dataToPrint.map((singleData) => {
        let startTime = hhmm(singleData.start);
        let endTime = hhmm(singleData.end);
        let breakTime = hhmm(singleData.break_time);
        let totalTime = singleData.total_time.toString();
        let signature = `../${singleData.signature_employee}`;
        if (currentPage === 1 && printingItem > 30) {
          currentPage++;
          printingItem = 1;
          yPosition = 18;
          pdf.addPage();
        } else if (currentPage > 1 && printingItem > 34) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        }
        pdf.text(
          singleData.name_employee,
          sideMargin + 5,
          yPosition + (printingItem - 1) * cellHeight
        );
        pdf.text(
          singleData.cod,
          sideMargin + 10 + columnWidth,
          yPosition + (printingItem - 1) * cellHeight
        );
        pdf.text(
          startTime,
          sideMargin + 5 + columnWidth * 2,
          yPosition + (printingItem - 1) * cellHeight
        );
        pdf.text(
          endTime,
          sideMargin + 5 + columnWidth * 3,
          yPosition + (printingItem - 1) * cellHeight
        );
        pdf.text(
          breakTime,
          sideMargin + 5 + columnWidth * 4,
          yPosition + (printingItem - 1) * cellHeight
        );
        pdf.text(
          totalTime,
          sideMargin + 10 + columnWidth * 5,
          yPosition + (printingItem - 1) * cellHeight
        );

     /*    let image = new Image();
        image.src = signature;
        pdf.addImage(
          image,
          "PNG",
          sideMargin + 5 + columnWidth * 6,
          yPosition + (printingItem - 1) * cellHeight - cellHeight / 2,
          10,
          5
        );
 */
        /*  pdf.text(
          breakTime,
          marginName + 80,
          yPosition + (printingItem - 1) * cellHeight
        ); */
        pdf.text(
          dateToMDA(dateToAMD(singleData.attendance_date)),
          sideMargin + 5 + columnWidth * 7,
          yPosition + (printingItem - 1) * cellHeight
        );
        printingItem++;
      });
      // Guardar el PDF

    pdf.save(`${report.project_number}_attendances.pdf`);
      //const blob = pdf.output("blob");

      // Crea una URL para el blob
      //const url = URL.createObjectURL(blob);

      // Abre una nueva ventana y muestra la vista previa del PDF
    //  window.open(url, "_blank");
    });
  };

  const getReport = async () => {
    setError({
      status: false,
      message: "",
      severity: "",
    });
    if (!range.date_start || !range.date_end) {
      setError({
        status: true,
        message: "You must select a valid range of days",
        severity: "error",
      });
      return;
    }

    setSending(true);
    const body = {
      date_start: range.date_start,
      date_end: range.date_end,
      id: projectId,
    };
    const data = await getAttendances(body);
    if(data.data?.status===200 && data.data?.data?.data?.data?.length<1){
      setSending(false);
      setError({
        status: true,
        message: "There are no projects with assistances for this dates",
        severity: "error",
      });
    }
    if (data.status==="error") {
      setSending(false);
      setError({
        status: true,
        message: data.message,
        severity: "error",
      });
      return;
    }
    let asistencias;
    type === "PDF"
      ? (asistencias = data.data.data.data.data)
      : (asistencias = data.data.data);
    if (data.data.status === 201) {
      setError({
        status: true,
        message: asistencias.message,
        severity: "error",
      });
      setSending(false);
      return;
    }
    if (asistencias.length > 0) {
      setAttendancesReport(asistencias);
      return;
    }
  };

  useEffect(() => {
    setSending(false);
    if (type === "PDF") {
      downloadPDF();
      return;
    }
    downloadExcel();
  }, [attendancesReport]);

  return (
    <section className="report create shape">
      <>
        <DatePick setStart={setStart} setEnd={setEnd} />
      </>

      {!sending ? (
        <MyButton text="Get Report" className="add mb-10" onClick={getReport} />
      ) : (
        <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
      )}
      <MyButton
        text="Go Back"
        className="logout"
        onClick={() => {
          if (type === "byProject") {
            setReportByDate(false);
          } else {
            navigate(-1);
          }
        }}
      />

      {error.status && (
        <Alert
          //  className="project_alert"
          severity={error.severity}
          sx={{ marginTop: "1rem" }}
        >
          {error.message}
        </Alert>
      )}
    </section>
  );
};

export default ReportByDate;
