export const employeeTypes = [
  {
    type: "",
    id: "",
  },
  {
    type: "ST",
    id: "1",
  },
  { type: "GL", id: "2" },
];

export const mapEmployeeType = (value) => {
  const incomingType = value;
  let mappedId = "";
  for (const employeeType of employeeTypes) {
    if (employeeType.type === incomingType) {
      mappedId = employeeType.id;
      break; // Salir del bucle una vez que se encuentre una coincidencia
    }
  }
  return mappedId
};

export const breakFraction = [
  {
    time: "0",
  },
  { time: "0.5" },
];
