


export const footerData = [
  "Healthcare Construction Solutions LLC",
  "Bank: Bank of America",
  "Account Number: 4460 4192 0538",
  "6673 Canada Goose Ct Frederick",
  "MD 21703 - 9546",
  "Phone: (240) 575-0159",
];

export const totalTitles = [
   "SUBTOTAL",
   "TAX RATE",
   "TAX"
]

